import React from 'react';
import { List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import uti from '../../assets/images/departments/prevent/uti.jpg';
import umeizudike from '../../assets/images/departments/prevent/umeizudike.png';
import sofola from '../../assets/images/departments/prevent/sofola.jpg';
import dolapo from '../../assets/images/departments/prevent/dolapo.jpg';
import ayanbadejo from '../../assets/images/departments/prevent/ayanbadejo.jpg';

const Preventive = () => {
  const services = [
    {
      name: 'Oral Diagnosis',
    },
    {
      name: 'Oral prophylaxis (Scaling and polishing)',
    },
    {
      name: 'Fluoride therapy',
    },
    {
      name: 'Smoking cessation services',
    },
    {
      name: 'Plaque control record ',
    },
    {
      name: 'Periodontal surgery',
    },
    {
      name: 'Non-surgical treatment of Periodontal diseases',
    },
    {
      name: 'Implant services',
    },
    {
      name: 'Oral biopsy services ',
    },
    {
      name: 'Sialometry',
    },
  ];

  return (
    <Layout pageTitle='Department of Preventive Dentistry | LUTH'>
      <NavOne />
      <PageHeader title='Department of Preventive Dentistry' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Preventive Dentistry
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler maxHeight={300} hideLabel='Hide' showLabel='Show More'>
                      <p className='course-details__tab-text'>
                        It gives me great pleasure to welcome you to the
                        Department of Preventive Dentistry in the Lagos
                        University Teaching Hospital. We place high value on
                        quality and impactful training, research, and service
                        delivery. We are a multispecialty department with
                        competent, goal and research-oriented academic members
                        of staff in the field of Oral diagnosis & Oral
                        Radiology, Oral Medicine, Periodontology and Community
                        Dentistry. Our academic staffs participate in quality
                        research and collaborate with other health related
                        disciplines, and participate in local and international
                        conferences on an impressive scale. Our positive
                        research output is evidenced by the large number of
                        publications in peer-reviewed, national and foreign
                        journals.
                        <br />
                        <br /> Most of our departmental offices are located on
                        the Second and Third Floor of the New Dental Block for
                        academic and administrative staff with internet
                        facilities for research and teaching purposes while the
                        clinic facilities are located on the Ground Floor as
                        well as in the Old Dental Block. The department also has
                        a modern Radiographic Facility for digital intraoral and
                        extraoral investigation, an Oral Hygiene Unit and a
                        Primary Oral Health Care Centre at Pakoto, affiliated to
                        the Lagos University Teaching Hospital. The department
                        has access to a well-equipped Faculty Library; Lecture
                        Hall [100-capacity on the first floor], Seminar Room
                        [50-capacity on the third floor] in the New Dental
                        Block.
                        <br />
                        <br />
                        Currently, the department trains undergraduate dental
                        students in Oral Diagnosis, Oral Radiology, Oral
                        Medicine, Periodontology and Community Dentistry which
                        are achieved through lectures, seminars, clinical
                        demonstrations, tutorials, and field trips in the fifth
                        and sixth clinical years of the undergraduate training.
                        The department is also involved in the postgraduate
                        training of Resident Doctors for the Award of the
                        Fellowship of the National Postgraduate Medical College
                        and the West African College of Surgeons in the
                        affiliated specialties in the department. Furthermore,
                        we are involved in the training of Dental Nursing
                        Diploma Programme with the hospital. The department
                        organizes Outreach Mobile Dental Services on a regular
                        basis approximating once every month to surrounding
                        communities and various locations across Lagos State.
                        <br />
                        <br />
                        The department of Preventive Dentistry was created at
                        the inception of the Dental School in 1966. Initially,
                        Child Dental Health was part of the department till
                        August 1, 1980 when it broke out of the department.
                        Presently, the Department is composed of the following
                        units: Oral Diagnosis, Oral Radiology & Oral Medicine,
                        Periodontology and Community Dentistry (with affiliated
                        Oral Hygiene sub-unit).
                        <br />
                        <br />
                        Past Heads of Department include Prof. Ana, Prof. F.
                        Okoisor, Prof. S. Jeboda, Prof. K. Savage, Prof. G.
                        Agbelusi, Prof. P. O. Ayanbadejo, Prof. O. O. Sofola,
                        Prof O. G. Uti, and Dr K. Umeizudike.
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 20 }}
                        >
                          Vision And Mission
                        </h2>
                        Our mission is to provide a conducive teaching,
                        learning, research and development environment, where
                        staff and students can interact and compete effectively
                        with their counterparts, both nationally and
                        internationally in terms of intellectual competence and
                        the zeal to add value to our world.
                        <br />
                        The Department of Preventive Dentistry believes in the
                        holistic approach to Oral Health Prevention and
                        management of Oral Diseases and improve the quality of
                        life of the Nigerian community as a whole.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={uti} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Uti, O. G.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Professor & Head of Department
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Bchd (OAU); MPH (Lagos); FMCDS (Lagos)                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={sofola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Sofola, O. O.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Professor & Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Bchd (OAU); MPH (Lagos); FMCDS (Lagos)                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ayanbadejo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Ayanbadejo, P. </h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Professor & Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos); FMCDS (Lagos); MPH (Lagos)                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={umeizudike} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Umeizudike, K. A.</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Associate Professor& Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS (Lagos); FMCDS (Lagos); MPH (Lagos)                         
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={dolapo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. G. O. Adekanmbi</h3>
                          <p>
                            <span className='detail-head'>Designations:</span>{' '}
                            Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BchD (Ife); FMCDS (Lagos)                           
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:prevdentist@luth.gov.ng'>                        
                        prevdentist@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Preventive;
